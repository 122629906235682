<template>
  <a-card :bordered="false">
    <div class="queryView">
      <div class="queryView-actions">
        <a-button type="primary" icon="plus-circle" @click="add()">新增</a-button>
        <a-button type="primary" icon="reload" @click="refreshRedis()">刷新缓存</a-button>
      </div>
      <div class="queryView-main">
        <a-table
          class="queryView-table"
          rowKey="mId"
          bordered
          :loading="loading"
          :columns="tableColumns"
          :dataSource="dataSource"
          :expandIconColumnIndex="0"
          :pagination="false"
          size="middle"
        ></a-table>
      </div>
    </div>
    <!-- 添加/编辑弹框 -->
    <a-modal :title="modal.title" v-model="modal.visible" @ok="handleOk">
      <a-form :form="modal.form" v-bind="modalFormLayout">
        <a-form-item label="父级">
          <a-tree-select
            allowClear
            v-decorator="['mFid']"
            class="input"
            :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
            :tree-data="menuTree.treeData"
            :replaceFields="menuTree.replaceFields"
            placeholder="请选择父级"
          ></a-tree-select>
        </a-form-item>
        <a-form-item label="名称">
          <a-input
            v-decorator="[
              'mName',
              {
                rules: [{ required: true, message: '请输入名称' }],
              },
            ]"
            placeholder="请输入名称"
          ></a-input>
        </a-form-item>
        <a-form-item label="描述">
          <a-input v-decorator="['remark']" placeholder="请输入描述"></a-input>
        </a-form-item>
        <a-form-item label="类型">
          <a-select
            v-decorator="[
              'typeOn',
              {
                rules: [{ required: true, message: '请选择类型' }],
              },
            ]"
            placeholder="请选择类型"
            :options="typesMap.menuType"
          ></a-select>
        </a-form-item>
        <a-form-item label="所属系统">
          <a-select
            v-decorator="[
              'type',
              {
                rules: [{ required: true, message: '请选择所属系统' }],
              },
            ]"
            placeholder="请选择所属系统"
            :options="systemType"
          ></a-select>
        </a-form-item>
        <a-form-item label="打开方式">
          <a-select
            v-decorator="[
              'typeUrl',
              {
                rules: [{ required: true, message: '请选择打开方式' }],
              },
            ]"
            placeholder="请选择打开方式"
            :options="typesMap.openType"
          ></a-select>
        </a-form-item>

        <a-form-item label="路径">
          <a-input v-decorator="['mUrl']" placeholder="请输入菜单路径"></a-input>
        </a-form-item>
        <a-form-item label="OA流程workflowid">
          <a-input v-decorator="['mParam']" placeholder="请输入OA流程workflowid"></a-input>
        </a-form-item>
        <a-form-item label="所属模块">
          <a-select
            v-decorator="[
              'mCode',
              {
                rules: [{ required: true, message: '请选择所属模块' }],
              },
            ]"
            placeholder="请选择所属模块"
            :options="typesMap.searchType"
          ></a-select>
        </a-form-item>
        <a-form-item label="排序号">
          <a-input-number
            v-decorator="[
              'mSort',
              {
                rules: [{ required: true, message: '请输入排序号' }],
              },
            ]"
            :min="1"
            :precision="0"
            placeholder="请输入排序号"
          ></a-input-number>
        </a-form-item>
        <a-form-item label="隐藏菜单">
          <a-checkbox
            v-decorator="[
              'mHide',
              {
                valuePropName: 'checked',
              },
            ]"
          ></a-checkbox>
        </a-form-item>
        <a-form-item hidden>
          <a-input
            v-decorator="[
              'mLevel',
              {
                initialValue: 1,
              },
            ]"
          ></a-input>
        </a-form-item>
      </a-form>
    </a-modal>
  </a-card>
</template>

<script>
import { queryViewMixin } from '@/utils/mixin'
import { getSystemType } from '@/service/getUnifyData'
export default {
  name: 'MenuManage',
  mixins: [queryViewMixin],
  data() {
    const tableColumns = [
      {
        title: '名称',
        width: '35%',
        dataIndex: 'mName',
        customRender: (text, row) => {
          return (
            <span class="menu-name">
              {text}
              {row.mHide && <span class="text-gray-400">【已隐藏】</span>}
            </span>
          )
        },
      },
      {
        title: '所属系统',
        width: '8%',
        dataIndex: 'type',
      },
      {
        title: '类型',
        width: '8%',
        dataIndex: 'typeOn',
      },
      {
        title: '路径',
        width: '15%',
        dataIndex: 'mUrl',
      },
      {
        title: '所属模块',
        width: '8%',
        dataIndex: 'mCode',
      },
      {
        title: '排序号',
        width: '5%',
        align: 'center',
        dataIndex: 'mSort',
      },
      {
        title: '操作',
        width: '15%',
        align: 'center',
        dataIndex: 'detail',
        customRender: (text, row, index) => {
          return (
            <div>
              <a
                class="row-action-btn"
                onClick={() => {
                  this.add(row)
                }}
              >
                新增
              </a>
              <a
                class="row-action-btn"
                onClick={() => {
                  this.doEdit(row)
                }}
              >
                修改
              </a>
              {row.mBuilt || (
                <a
                  class="row-action-btn"
                  onClick={() => {
                    this.doDelete(row)
                  }}
                >
                  删除
                </a>
              )}
            </div>
          )
        },
      },
    ]
    return {
      form: this.$form.createForm(this),
      loading: false,
      tableColumns: tableColumns,
      dataSource: [],
      modal: {
        visible: false,
        data: {},
        form: this.$form.createForm(this),
        title: '',
      },

      // 页面初始化数据
      menuTree: {
        treeData: [],
        replaceFields: { children: 'children', title: 'mName', value: 'mId' },
      },
      systemType: [],
    }
  },
  computed: {
    typesMap() {
      return {
        menuType: [
          // 目录的url不会和本地的路由关联
          { value: 'M', label: '目录' },
          { value: 'C', label: '菜单' },
          // { value: 'A', label: '按钮' },
        ],
        openType: [
          { value: 'NB', label: '当前页面' },
          { value: 'WB', label: '新开窗口' },
        ],
        searchType: [
          { value: 'DJ', label: '顶级' },
          { value: 'GZ', label: '工作台' },
          { value: 'SJ', label: '数据查询' },
          { value: 'BI', label: '智能BI' },
          { value: 'GQ', label: '工期' },
          { value: 'YY', label: '应用系统' },
        ],
      }
    },
    modalFormLayout() {
      return {
        labelCol: {
          span: 6,
        },
        wrapperCol: {
          span: 18,
        },
      }
    },
  },

  methods: {
    async refreshRedis() {
      await this.$axios.get('/unify-home/admin/menu/removeRedis')
      this.$message.success('操作成功')
    },
    add(row) {
      this.resetModal()
      this.modal.visible = true
      this.modal.title = '新增'

      if (row) {
        this.safeSetFieldsValue(this.modal.form, {
          mFid: row.mId,
          mLevel: row.mLevel + 1,
        })
      }
    },
    async doDelete(row) {
      await new Promise((resolve, reject) => {
        this.$confirm({
          title: '提示',
          content: '确定删除记录?',
          onOk: () => {
            resolve()
          },
          onCancel: () => {
            reject(new Error('E_CANCEL'))
          },
        })
      })
      await this.$axios.post(`/unify-home/admin/menu/delete`, {
        mId: row.mId,
      })
      this.$message.success('操作成功!')
      this.loadMenu()
    },
    doEdit(row) {
      this.resetModal()
      this.modal.visible = true
      this.modal.title = '修改'
      const data = { ...row }
      if (data.mFid === '0') {
        data.mFid = null
      }

      this.modal.data = data
      this.safeSetFieldsValue(this.modal.form, data)
    },
    handleOk() {
      this.modal.form.validateFields(async (errors, values) => {
        console.log(values)
        if (!errors) {
          const params = values
          const { mId } = this.modal.data
          try {
            if (!mId) {
              await this.$axios.post('/unify-home/admin/menu/saveJson', {
                ...params,
              })
            } else {
              await this.$axios.post('/unify-home/admin/menu/updateJson', {
                mId,
                ...params,
              })
            }

            this.$message.success('操作成功!')
            this.loadMenu()
          } catch (e) {
            console.error(e)
          }

          this.modal.visible = false
        }
      })
    },
    async loadMenu() {
      this.loading = true
      try {
        const res = await this.$axios.post('/unify-home/admin/menu/list')
        this.dataSource = res.data
        this.menuTree.treeData = res.data
      } catch (e) {}
      this.loading = false
    },
  },
  async mounted() {
    this.systemType = await getSystemType()
    this.loadMenu()
  },
}
</script>

<style lang="less" scoped>
.menu-name {
  display: block;
  margin-top: -21px;
}
::v-deep {
  .ant-table-row-level-0 {
    .menu-name {
      margin-left: 25px;
    }
  }
  .ant-table-row-level-1 {
    .menu-name {
      margin-left: 45px;
    }
  }
  .ant-table-row-level-2 {
    .menu-name {
      margin-left: 65px;
    }
  }
  .ant-table-row-level-3 {
    .menu-name {
      margin-left: 85px;
    }
  }
}

.tabs {
  width: 100%;
  height: 38px;
  background: #eeeeee;
  border-radius: 4px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  margin-bottom: 20px;
  display: flex;
  .btn {
    width: 100px;
    height: 100%;
    line-height: 38px;
    text-align: center;
    font-size: 14px;
    color: #333333;
    cursor: pointer;
  }
  .active {
    background: #ffffff;
  }
}
.row-action-btn {
  margin: 0 5px;
}
</style>
