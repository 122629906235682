var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-card',{attrs:{"bordered":false}},[_c('div',{staticClass:"queryView"},[_c('div',{staticClass:"queryView-actions"},[_c('a-button',{attrs:{"type":"primary","icon":"plus-circle"},on:{"click":function($event){return _vm.add()}}},[_vm._v("新增")]),_c('a-button',{attrs:{"type":"primary","icon":"reload"},on:{"click":function($event){return _vm.refreshRedis()}}},[_vm._v("刷新缓存")])],1),_c('div',{staticClass:"queryView-main"},[_c('a-table',{staticClass:"queryView-table",attrs:{"rowKey":"mId","bordered":"","loading":_vm.loading,"columns":_vm.tableColumns,"dataSource":_vm.dataSource,"expandIconColumnIndex":0,"pagination":false,"size":"middle"}})],1)]),_c('a-modal',{attrs:{"title":_vm.modal.title},on:{"ok":_vm.handleOk},model:{value:(_vm.modal.visible),callback:function ($$v) {_vm.$set(_vm.modal, "visible", $$v)},expression:"modal.visible"}},[_c('a-form',_vm._b({attrs:{"form":_vm.modal.form}},'a-form',_vm.modalFormLayout,false),[_c('a-form-item',{attrs:{"label":"父级"}},[_c('a-tree-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['mFid']),expression:"['mFid']"}],staticClass:"input",attrs:{"allowClear":"","dropdown-style":{ maxHeight: '400px', overflow: 'auto' },"tree-data":_vm.menuTree.treeData,"replaceFields":_vm.menuTree.replaceFields,"placeholder":"请选择父级"}})],1),_c('a-form-item',{attrs:{"label":"名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'mName',
            {
              rules: [{ required: true, message: '请输入名称' }],
            } ]),expression:"[\n            'mName',\n            {\n              rules: [{ required: true, message: '请输入名称' }],\n            },\n          ]"}],attrs:{"placeholder":"请输入名称"}})],1),_c('a-form-item',{attrs:{"label":"描述"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['remark']),expression:"['remark']"}],attrs:{"placeholder":"请输入描述"}})],1),_c('a-form-item',{attrs:{"label":"类型"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'typeOn',
            {
              rules: [{ required: true, message: '请选择类型' }],
            } ]),expression:"[\n            'typeOn',\n            {\n              rules: [{ required: true, message: '请选择类型' }],\n            },\n          ]"}],attrs:{"placeholder":"请选择类型","options":_vm.typesMap.menuType}})],1),_c('a-form-item',{attrs:{"label":"所属系统"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'type',
            {
              rules: [{ required: true, message: '请选择所属系统' }],
            } ]),expression:"[\n            'type',\n            {\n              rules: [{ required: true, message: '请选择所属系统' }],\n            },\n          ]"}],attrs:{"placeholder":"请选择所属系统","options":_vm.systemType}})],1),_c('a-form-item',{attrs:{"label":"打开方式"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'typeUrl',
            {
              rules: [{ required: true, message: '请选择打开方式' }],
            } ]),expression:"[\n            'typeUrl',\n            {\n              rules: [{ required: true, message: '请选择打开方式' }],\n            },\n          ]"}],attrs:{"placeholder":"请选择打开方式","options":_vm.typesMap.openType}})],1),_c('a-form-item',{attrs:{"label":"路径"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['mUrl']),expression:"['mUrl']"}],attrs:{"placeholder":"请输入菜单路径"}})],1),_c('a-form-item',{attrs:{"label":"OA流程workflowid"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['mParam']),expression:"['mParam']"}],attrs:{"placeholder":"请输入OA流程workflowid"}})],1),_c('a-form-item',{attrs:{"label":"所属模块"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'mCode',
            {
              rules: [{ required: true, message: '请选择所属模块' }],
            } ]),expression:"[\n            'mCode',\n            {\n              rules: [{ required: true, message: '请选择所属模块' }],\n            },\n          ]"}],attrs:{"placeholder":"请选择所属模块","options":_vm.typesMap.searchType}})],1),_c('a-form-item',{attrs:{"label":"排序号"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'mSort',
            {
              rules: [{ required: true, message: '请输入排序号' }],
            } ]),expression:"[\n            'mSort',\n            {\n              rules: [{ required: true, message: '请输入排序号' }],\n            },\n          ]"}],attrs:{"min":1,"precision":0,"placeholder":"请输入排序号"}})],1),_c('a-form-item',{attrs:{"label":"隐藏菜单"}},[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'mHide',
            {
              valuePropName: 'checked',
            } ]),expression:"[\n            'mHide',\n            {\n              valuePropName: 'checked',\n            },\n          ]"}]})],1),_c('a-form-item',{attrs:{"hidden":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'mLevel',
            {
              initialValue: 1,
            } ]),expression:"[\n            'mLevel',\n            {\n              initialValue: 1,\n            },\n          ]"}]})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }